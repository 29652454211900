<template>
  <div>
    <th-input-title
      :info="$t('pages.settings.general.support.description')"
      :title="$t('pages.settings.general.support.title')"
    />

    <el-radio-group v-model="allowSupport">
      <el-radio-button :label="false">
        {{ $t('pages.settings.general.support.disallow') }}
      </el-radio-button>
      <el-radio-button :label="true">
        {{ $t('pages.settings.general.support.allow') }}
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  name: 'Support',
  computed: {
    allowSupport: {
      get() {
        const storeVal = safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'settings.support'
        )
        return storeVal !== undefined ? storeVal : true
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.support',
          value: newValue
        })
      }
    }
  }
}
</script>
