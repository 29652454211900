<template>
  <div>
    <th-input-title
      :title="$t('pages.settings.general.default_timezone.title')"
    />

    <el-select
      v-model="value"
      v-cancel-read-only
      filterable
      clearable
      :placeholder="getTimeZone"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import zones from './timezones.json'

export default {
  data() {
    return {
      options: zones.map((item) => ({ label: item, value: item }))
    }
  },
  computed: {
    ...mapGetters('Config', ['getTimeZone']),
    value: {
      get: function () {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.default_timezone'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.default_timezone',
          value: newValue || null
        })
      }
    }
  }
}
</script>

<style scoped></style>
