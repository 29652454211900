<template>
  <div>
    <th-input-title
      :title="$t('pages.settings.general.default_date_selected.title')"
    />

    <el-radio-group v-model="value">
      <el-radio-button label="today">
        {{ $t('pages.settings.general.default_date_selected.today') }}
      </el-radio-button>
      <el-radio-button label="thisWeek">
        {{ $t('pages.settings.general.default_date_selected.this_week') }}
      </el-radio-button>
      <el-radio-button label="thisMonth">
        {{ $t('pages.settings.general.default_date_selected.this_month') }}
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    value: {
      get() {
        return this.$store.getters['Config/getDefaultDateSelected']
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.default_date_selected',
          value: newValue || 'today'
        })
      }
    }
  }
}
</script>
