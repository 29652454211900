<template>
  <div>
    <th-input-title
      :title="$t('pages.settings.general.dashboard_language.title')"
    />

    <el-select
      v-model="value"
      v-cancel-read-only
      filterable
      clearable
      :placeholder="$t('common.interactions.selects.placeholder')"
    >
      <el-option
        v-for="item in locales"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import { locales } from '@/constants'

export default {
  data() {
    return {
      locales
    }
  },
  computed: {
    value: {
      get() {
        return safeGet(
          this.$store.state.Config.localConfiguration,
          'settings.language'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setLocalConfigurationValue', {
          path: 'settings.language',
          value: newValue || null
        })
        this.$rootMaybeChangeLocale(
          newValue || this.$store.getters['Config/getLocale']
        )
      }
    }
  }
}
</script>
