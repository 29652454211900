<template>
  <div>
    <th-input-title
      :info="$t('pages.settings.general.debug.tooltip')"
      :title="
        $t('pages.settings.general.debug.title') +
        ' ' +
        $t(`pages.settings.general.debug.global`)
      "
    />

    <el-radio-group v-model="global">
      <el-radio-button label="none">
        {{ $t('pages.settings.general.debug.none') }}
      </el-radio-button>
      <el-radio-button label="data_mode">
        {{ $t('pages.settings.general.debug.data_mode') }}
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  data() {
    return {}
  },
  computed: {
    global: {
      get() {
        return (
          safeGet(
            this.$store.state.Config.clientAccountConfiguration,
            'settings.debug.global'
          ) || 'none'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.debug.global',
          value: newValue || 'none'
        })
      }
    }
  }
}
</script>
