<template>
  <el-row :gutter="30">
    <el-col :md="12">
      <th-wrapper>
        <account-language class="mb-6" />
        <dashboard-language class="mb-6" />
        <default-timezone class="mb-6" />
        <default-date-format class="mb-6" />
        <default-time-format class="mb-6" />
        <default-currency v-if="!isUnifiedCommerceClient" />
      </th-wrapper>
    </el-col>
    <el-col :md="12">
      <th-wrapper>
        <support v-if="!isUnifiedCommerceClient" class="mb-6" />
        <debug v-if="!isUnifiedCommerceClient" class="mb-6" />
        <navigation-after-creation
          v-if="!isUnifiedCommerceClient"
          class="mb-6"
        />
        <default-date-selected />
        <!-- TODO: Remove if nobody misses it -->
        <!-- <table-borders /> -->
      </th-wrapper>
    </el-col>
  </el-row>
</template>

<script>
import DashboardLanguage from './components/dashboard-language'
import AccountLanguage from './components/account-language'
import DefaultTimezone from './components/default-timezone'
import DefaultDateFormat from './components/default-date-format'
import DefaultTimeFormat from './components/default-time-format'
import DefaultCurrency from './components/default-currency'
import Support from './components/support'
import Debug from './components/debug'
import NavigationAfterCreation from './components/navigation-after-creation'
import DefaultDateSelected from './components/default-date-selected'
import { isUnifiedCommerce } from '@/constants/index'
// import TableBorders from './components/table-borders'

export default {
  components: {
    DashboardLanguage,
    AccountLanguage,
    DefaultTimezone,
    DefaultDateFormat,
    DefaultTimeFormat,
    DefaultCurrency,
    Support,
    NavigationAfterCreation,
    DefaultDateSelected,
    Debug
    // TableBorders
  },
  computed: {
    isUnifiedCommerceClient() {
      return isUnifiedCommerce()
    }
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.general.title')
    }
  }
}
</script>
