<template>
  <div>
    <th-input-title
      :title="$t('pages.settings.general.default_currency.title')"
    />

    <th-currency-select v-model="value" />
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get: function () {
        return this.$store.getters['Config/getCurrentDefaultCurrency']
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.default_currency',
          value: newValue || null
        })
      }
    }
  }
}
</script>
