<template>
  <div>
    <th-input-title
      :title="$t('pages.settings.general.account_language.title')"
    />

    <el-select
      v-model="value"
      v-cancel-read-only
      filterable
      :placeholder="$t('common.interactions.selects.placeholder')"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import { locales } from '@/constants'

export default {
  data() {
    return {
      options: locales
    }
  },
  computed: {
    value: {
      get() {
        return (
          safeGet(
            this.$store.state.Config.clientAccountConfiguration,
            'settings.language'
          ) || 'en-US'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.language',
          value: newValue
        })
        this.$rootMaybeChangeLocale(newValue)
      }
    }
  }
}
</script>
