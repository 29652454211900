<template>
  <div>
    <th-input-title
      :info="$t('pages.settings.general.navigation_after_creation.tooltip')"
      :title="$t('pages.settings.general.navigation_after_creation.title')"
    />

    <div class="row">
      <el-radio-group v-model="value">
        <el-radio-button label="list">
          {{ $t('pages.settings.general.navigation_after_creation.list') }}
        </el-radio-button>
        <el-radio-button label="edit">
          {{ $t('pages.settings.general.navigation_after_creation.edit') }}
        </el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    value: {
      get() {
        return this.$store.getters['Config/getNavigationAfterCreation']
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.navigation_after_creation',
          value: newValue || 'list'
        })
      }
    }
  }
}
</script>

<style scoped>
.label {
  margin-bottom: 5px;
}

.row {
  display: flex;
}
</style>
