<template>
  <div>
    <th-input-title
      :title="$t('pages.settings.general.default_date_format.title')"
    />

    <el-select
      v-model="value"
      filterable
      clearable
      :placeholder="$t('common.interactions.selects.placeholder')"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :value="item.value"
        :label="item.label"
      />
    </el-select>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  data() {
    return {
      options: [
        {
          value: 'dd/MM/yyyy',
          label: this.$t(
            'pages.settings.general.default_date_format.options.dd/MM/yyyy'
          )
        },
        {
          value: 'dd.MM.yyyy',
          label: this.$t(
            'pages.settings.general.default_date_format.options.dd_MM_yyyy'
          )
        },
        {
          value: 'MM/dd/yyyy',
          label: this.$t(
            'pages.settings.general.default_date_format.options.MM/dd/yyyy'
          )
        },
        {
          value: 'yyyy/MM/dd',
          label: this.$t(
            'pages.settings.general.default_date_format.options.yyyy/mm/dd'
          )
        }
      ]
    }
  },
  computed: {
    value: {
      get: function () {
        return (
          safeGet(
            this.$store.getters['Config/getClientAccountConfiguration'],
            'settings.default_date_format'
          ) || 'dd.MM.yyyy'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.default_date_format',
          value: newValue || 'dd.MM.yyyy'
        })
      }
    }
  }
}
</script>
